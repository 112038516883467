import React from "react";
import Layout from "components/layout";
import SEO from "components/seo";
import Ottawa from "components/locationIndexPages/Ottawa";

export default function index() {
  const title = "Ottawa Translation Services Agency - Tomedes";
  const description =
    "Need translation services in Ottawa? Tomedes provides translation, localization, and interpretation in over 120+ languages with rapid delivery, 24/7 customer service and 1-year guarantee.";
  const keywords = "Translation Services in Ottawa";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/locations/ottawa-translation-services"
      />
      <Ottawa />
    </Layout>
  );
}
